<template>
    <div class="dashboard bg-white rounded-lg mt-4">
        <div class="section-title">請選擇匯出的資料範圍</div>
        <div class="inputs">
            <div class="input">
                <div class="label">資料分類</div>
                <el-select
                    v-model="type"
                    class="w-full"
                    placeholder="請選擇資料分類"
                    clearable
                >
                    <el-option
                        v-for="(status, index) in dataTypes"
                        :key="index"
                        :label="status.label"
                        :value="status.value"
                    >
                        {{ status.label }}
                    </el-option>
                </el-select>
            </div>
            <div v-if="type !== 5" class="input">
                <div class="label">{{ showInputDateLabel }}</div>
                <el-date-picker
                    v-model="dateInputs"
                    style="width: 100%"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="開始日期"
                    end-placeholder="结束日期"
                >
                </el-date-picker>
            </div>
            <div v-if="type === 1" class="input">
                <div class="label">收益狀態</div>
                <el-select
                    v-model="hasMemberPaid"
                    class="w-full"
                    placeholder="請選擇付款狀態"
                    clearable
                >
                    <el-option :key="0" label="全選" :value="0">
                        全選
                    </el-option>
                    <el-option :key="1" label="有收益" :value="1">
                        有收益
                    </el-option>
                </el-select>
            </div>
            <div v-if="type === 2" class="input">
                <div class="label">付款狀態</div>
                <el-select
                    v-model="isPaid"
                    class="w-full"
                    placeholder="請選擇付款狀態"
                    clearable
                >
                    <el-option :key="0" label="全選" :value="0">
                        全選
                    </el-option>
                    <el-option :key="1" label="已付款" :value="1">
                        已付款
                    </el-option>
                </el-select>
            </div>
        </div>
        <div v-if="type" class="select-area">
            <div class="section-title">
                請選擇報表欄位
                <el-checkbox
                    v-model="selectAll"
                    class="ml-3"
                    label="all"
                    @change="choiceAll"
                    >全選</el-checkbox
                >
            </div>
            <el-checkbox-group v-model="checked" class="flex flex-wrap">
                <el-checkbox
                    v-for="(val, key) in rows[type]"
                    :key="key"
                    class="checkbox"
                    :label="key"
                    >{{ val }}</el-checkbox
                >
            </el-checkbox-group>
            <div class="actions">
                <el-button
                    :disabled="!isActived"
                    :loading="loading"
                    class="orange-btn-800-lg text-md"
                    @click="formatDatas"
                    >匯出資料</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { saveCSV } from "@/service/csvFile";
// 地區
import { areas } from "@/langs/tw.json";
// 訂單相關
import orderConfig from "@/config/orderConfig.js";
// 付款相關
import accountConfig from "@/config/accountConfig.js";
export default {
    name: "exportExcel",
    computed: {
        dataTypes() {
            return [
                { label: "訂單記錄", value: 1 },
                { label: "付款記錄", value: 2 },
                { label: "代付款項記錄", value: 3 },
                { label: "額外小費記錄", value: 4 },
                { label: "服務商資料", value: 5 },
            ];
        },
        showInputDateLabel() {
            if (this.type === 1) {
                return "預訂開始日期區間";
            } else if (this.type === 2 || this.type === 3) {
                return "建立日期區間";
            } else {
                return "日期區間";
            }
        },
        // 判定匯出資料按鈕是否可按
        isActived() {
            if (this.type === 5) {
                return this.checked.length > 0;
            }
            return this.type && this.checked.length > 0 && this.dateInputs;
        },
    },
    data() {
        return {
            loading: false,
            type: null, // 選擇匯出種類
            dateInputs: [], // 日期輸入匡
            checked: [], // 已勾選欄位
            sortChecked: [], // 已勾選欄位排序之後
            selectAll: false,
            hasMemberPaid: 0, // 有收益
            isPaid: 0, // 已付款狀態
            rows: {
                1: {
                    id: "內部序號",
                    order_id: "訂單編號",
                    user_id: "會員編號",
                    user_name: "會員名稱",
                    provider_id: "服務商編號",
                    provider_name: "服務商名稱",
                    category_name: "活動項目",
                    description: "訂單備註",
                    district: "預訂城市",
                    location: "預訂地點",
                    // details: "細節資訊",
                    status: "訂單狀態",
                    paid_by: "付款方式",
                    單價: "單價",
                    時數: "時數",
                    voucherUsed: "快閃折抵金使用",
                    小費: "小費",
                    price: "訂單合計",
                    gross_price: "訂單總金額",
                    paid: "會員已付",
                    point_paid: "會員實付儲值金",
                    voucher_paid: "實付折抵金",
                    provider_remuneration: "服務商收益",
                    消費服務費: "消費服務費",
                    系統服務費: "系統服務費",
                    平台實收: "平台實收",
                    user_score: "服務商給予評分",
                    user_comment: "服務商給予評論",
                    provider_score: "會員給予評分",
                    provider_comment: "會員給予評論",
                    started_at: "預訂開始時間",
                    ended_at: "預訂結束時間",
                    created_at: "建立時間",
                },
                2: {
                    id: "內部序號",
                    user_id: "使用者編號",
                    order_id: "交易單編號",
                    type: "付款方式",
                    amount: "付款金額",
                    reason: "付款原因",
                    status: "付款狀態",
                    pay_time: "付款時間",
                    created_at: "建立時間",
                },
                3: {
                    id: "內部序號",
                    user_id: "使用者編號",
                    transaction_log_id: "金流編號",
                    type: "付款方式",
                    amount: "付款金額",
                    reason: "代付款項原因",
                    banking: "收款銀行資訊",
                    status: "付款狀態",
                    log: "操作記錄",
                    created_at: "建立/申請時間",
                    export_time: "匯出時間",
                    complete_time: "完成時間",
                    transaction_date: "預計撥款日",
                },
                4: {
                    id: "內部序號",
                    order_id: "訂單編號",
                    user_id: "會員編號",
                    user_name: "會員名稱",
                    provider_id: "服務商編號",
                    provider_name: "服務商名稱",
                    category_name: "活動項目",
                    provider_pay: "服務商收益",
                    system_pay: "系統服務費",
                    extra_tip_created_at: "小費給予時間",
                    extra_tip: "額外小費金額",
                },
                5: {
                    name: "暱稱",
                    phone: "手機",
                    real_name: "真實姓名",
                    service_area: "服務地點",
                    broker_id: "經紀公司/介紹人",
                    created_at: "註冊日期",
                    role: "上架狀態",
                },
            },
            allDatas: [],
            // 需列入合計項目
            sumKeys: [
                "price",
                "gross_price",
                "paid",
                "provider_remuneration",
                "voucherUsed",
                "amount",
                "point_paid",
                "voucher_paid",
                "小費",
                "消費服務費",
                "系統服務費",
                "平台實收",
                "時數",
            ],
            // 訂單狀態 status
            orderStatus: orderConfig.orderStatus,
            // 付款紀錄
            payTypes: accountConfig.payTypes,
            payCauses: accountConfig.payCauses,
            payStatus: accountConfig.payStatus,
            // 代付款項
            payReasons: accountConfig.payReasons,
            accountStatus: accountConfig.accountStatus,
            accountStatusText: accountConfig.accountStatusText,
        };
    },
    methods: {
        // 全選
        choiceAll(val) {
            if (val) {
                const result = [];
                for (const i in this.rows[this.type]) {
                    result.push(i);
                }
                this.checked = [...result];
                this.sortChecked = [...result];
            } else {
                this.checked = [];
                this.sortChecked = [];
            }
        },
        formatDatas() {
            if (this.type === 1) {
                this.orderRecords();
            } else if (this.type === 2) {
                this.paymentRecords();
            } else if (this.type === 3) {
                this.remittanceRecords();
            } else if (this.type === 4) {
                this.extraTipRecords();
            } else if (this.type === 5) {
                this.providerList();
            }
        },
        // 最後匯出 CSV 部分
        exportCSV() {
            // 整理 csv 資訊
            // csv 標頭設置
            const head = ["#", ...this.sortChecked];
            const csvHead = head.map((i) => {
                return i === "#" ? "#" : this.rows[this.type][i];
            });
            // csv body設置
            const body = [];
            // 總計欄位
            const sum = ["金額總計"];
            this.allDatas.forEach((i, idx) => {
                const data = [];
                head.forEach((j, index) => {
                    // 編號排序
                    if (j === "#") {
                        data.push(idx + 1);
                    } else {
                        // 個別欄位排序
                        data.push(i[j] !== null ? i[j] : "");
                        // 將需要列入總計的欄位加總
                        if (
                            typeof i[j] === "number" &&
                            this.sumKeys.indexOf(j) !== -1
                        ) {
                            sum[index] = sum[index]
                                ? sum[index] + parseInt(i[j])
                                : i[j];
                        }
                    }
                });
                body.push(data);
            });
            body.push(sum);
            // 匯出
            let fileName = "";
            if (this.type == 1) {
                fileName = `dating-${moment().format("YYYYMMDDHHmm")}`;
            } else if (this.type == 2) {
                fileName = `payment-${moment().format("YYYYMMDDHHmm")}`;
            } else if (this.type === 3) {
                fileName = `remittance-${moment().format("YYYYMMDDHHmm")}`;
            } else if (this.type === 4) {
                fileName = `extratip-${moment().format("YYYYMMDDHHmm")}`;
            } else {
                fileName = `provider-${moment().format("YYYYMMDDHHmm")}`;
            }
            saveCSV(fileName, csvHead, body);
        },
        // API
        // 訂單記錄 API
        orderRecords() {
            this.loading = true;
            const req = {
                limit: 100000,
                started_at_start: this.dateInputs[0],
                started_at_end: this.dateInputs[1],
            };
            this.$api
                .SearchAllDatings(req)
                .then((res) => {
                    this.allDatas = res.data.data
                        .filter((j) => {
                            // 篩選是否有實際收益訂單
                            if (this.hasMemberPaid !== 0) {
                                return j.paid > 0;
                            }
                            return true;
                        })
                        .map((i) => {
                            const temp = {
                                ...i,
                                user_id: i.user ? i.user.banana_id : "",
                                created_at: moment(i.created_at).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                ),
                                user_name: i.user ? i.user.name : "",
                                provider_id: i.provider
                                    ? i.provider.banana_id
                                    : "",
                                provider_name: i.provider
                                    ? i.provider.name
                                    : "",
                                category_name: i.category
                                    ? i.category.name
                                    : "",
                                district: areas[i.district]
                                    ? areas[i.district].name
                                    : "",
                                status: i.status
                                    ? this.orderStatus[i.status]
                                    : "",
                                voucherUsed: i.details.voucherUsed
                                    ? i.details.voucherUsed
                                    : 0,
                                小費: i.details.tip,
                                單價: i.details.hourlyPrice,
                                時數: i.details.duration,
                                // 消費服務費: 會員臨時取消時為 0; 其他狀態為 fee
                                消費服務費:
                                    i.status === -3
                                        ? 0
                                        : i.paid > 0
                                            ? i.details.fee
                                            : 0,
                                // 系統服務費: 會員臨時取消時為已付金額的 30% (已付金額-服務商收益); 其他狀態為訂單合計 - 服務商收益
                                系統服務費:
                                    i.status === -3
                                        ? i.paid - i.provider_remuneration
                                        : i.paid > 0
                                            ? i.price - i.provider_remuneration
                                            : 0,
                                // 平台實收: 會員臨時取消時 (平台實收 = 系統服務費); 其他狀態為訂單總額 - 服務商收益 - 優惠卷價格
                                平台實收: checkValue(
                                    i.status,
                                    i.paid,
                                    i.provider_remuneration,
                                    i.gross_price -
                                        i.provider_remuneration -
                                        i.details.voucherUsed
                                ),
                                paid_by: i.paid_by === 0 ? "儲值金" : "現金單",
                            };
                            console.log(i.extra_tip, i.extra_tip_created_at);
                            return temp;
                        });
                    this.exportCSV();
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({ type: "error", message: "匯出資料錯誤" });
                })
                .finally(() => {
                    this.loading = false;
                });
            function checkValue(status, paid, provider_remuneration, value) {
                return status === -3
                    ? paid - provider_remuneration
                    : paid > 0
                        ? value
                        : 0;
            }
        },
        // 付款資訊 API
        paymentRecords() {
            this.loading = true;
            const req = {
                limit: 100000,
                created_at_start: this.dateInputs[0],
                created_at_end: this.dateInputs[1],
            };
            this.$api
                .SearchPaymentsAPI(req)
                .then((res) => {
                    this.allDatas = res.data.data
                        .filter((j) => {
                            // 篩選是否有實際到帳
                            if (this.isPaid !== 0) {
                                return j.status === 1;
                            }
                            return true;
                        })
                        .map((i) => {
                            return {
                                ...i,
                                created_at: moment(i.created_at).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                ),
                                user_id: i.user ? i.user.banana_id : "",
                                type: this.payTypes[i.type],
                                reason: this.payCauses[i.reason],
                                status: this.payStatus[i.status],
                            };
                        });
                    this.exportCSV();
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({ type: "error", message: "匯出資料錯誤" });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 代付款項紀錄 API
        remittanceRecords() {
            this.loading = true;
            const req = {
                limit: 100000,
                created_date_start: this.dateInputs[0],
                created_date_end: this.dateInputs[1],
                sort_by: "created_at",
            };
            this.$api
                .SearchRemittancesAPI(req)
                .then((res) => {
                    this.allDatas = res.data.data.map((i) => {
                        // 操作紀錄
                        let log = "";
                        i.log.forEach((j) => {
                            log += `狀態： ${
                                this.accountStatusText[j.status]
                            } 時間：${j.time} ${
                                j.user_type === "PROVIDER"
                                    ? i.user.name
                                    : "管理者"
                            } \n`;
                        });
                        return {
                            ...i,
                            user_id: i.user ? i.user.banana_id : "",
                            created_at: moment(i.created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                            ),
                            type: i.type,
                            reason: this.payReasons[i.reason],
                            banking: `銀行代碼:${i.banking.bankCode}\n通匯代碼:${i.banking.swift}\n銀行帳號:${i.banking.accountId}\n戶名:${i.banking.accountName}`,
                            log,
                            status: this.accountStatus[i.status],
                        };
                    });
                    this.exportCSV();
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({ type: "error", message: "匯出資料錯誤" });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 額外小費紀錄 API
        extraTipRecords() {
            this.loading = true;
            const req = {
                limit: 100000,
                extra_tip_created_at_start: this.dateInputs[0],
                extra_tip_created_at_end: this.dateInputs[1],
            };
            this.$api
                .SearchAllDatings(req)
                .then((res) => {
                    this.allDatas = res.data.data.map((i) => {
                        return {
                            ...i,
                            user_id: i.user ? i.user.banana_id : "",
                            user_name: i.user ? i.user.name : "",
                            created_at: moment(i.created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                            ),
                            type: i.type,
                            provider_id: i.provider ? i.provider.banana_id : "",
                            provider_name: i.provider ? i.provider.name : "",
                            category_name: i.category ? i.category.name : "",
                            status: i.status ? this.orderStatus[i.status] : "",
                            // 服務商收益
                            provider_pay: Math.floor(i.extra_tip * 0.3),
                            // 系統服務費收益
                            system_pay: Math.ceil(i.extra_tip * 0.7),
                            額外小費: i.extra_tip,
                            額外小費給予日期: i.extra_tip_created_at,
                        };
                    });
                    this.exportCSV();
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({ type: "error", message: "匯出資料錯誤" });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 服務商列表 API
        providerList() {
            this.loading = true;
            const req = {
                roles: [1, 2],
                sort_type: "desc",
                limit: 10000,
            };
            this.$api
                .GetProviderListApi(req)
                .then((res) => {
                    this.allDatas = res.data.data.map((i) => {
                        return {
                            ...i,
                            service_area: areas[i.service_area]
                                ? areas[i.service_area].name
                                : "",
                            role: i.role == 1 ? "已上架" : "未上架",
                            created_at: moment(i.created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                            ),
                            broker_id: i.broker ? i.broker.name : "",
                        };
                    });
                    this.exportCSV();
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({ type: "error", message: "匯出資料錯誤" });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    watch: {
        checked: {
            deep: true,
            handler(val) {
                //  設定 sortChecked , 依照欄位順序做排序
                const result = [];
                for (const i in this.rows[this.type]) {
                    if (val.indexOf(i) > -1) {
                        result.push(i);
                    }
                }
                this.sortChecked = result;
                if (val.length === Object.keys(this.rows[this.type]).length) {
                    this.selectAll = true;
                } else {
                    this.selectAll = false;
                }
            },
        },
        // 切換資料分類清空所選
        type() {
            this.checked = [];
            this.dateInputs = null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_text-style.scss";

.dashboard {
    padding: 30px 8%;
    .section-title {
        width: 80%;
        margin-bottom: 20px;
        @extend .text-black-blod-18;
    }
    .inputs {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        .input {
            flex: 1;
            margin-top: 10px;
            padding-right: 50px;
            .label {
                color: #757575;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 5px;
                width: 200px;
            }
        }
    }
    .select-area {
        padding-top: 30px;
        border-top: solid 1px #cbcbcb;
    }
    .checkbox {
        width: 25%;
        margin: 10px 0;
    }
    .actions {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 30px;
    }
}
</style>
